/* eslint-disable max-lines */
import React, { ReactElement, useMemo, useState, useCallback } from 'react';
import { CaretDownOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import { MultiSelect, Option } from 'react-multi-select-component';
import { useFilterState } from '../../components/portfolio-performance/useFilterState';
import { FilterDropDown } from '../../elements/dropDown';
import { Account } from '../../models/account';
import { SubOrdinates } from '../../models/am-hierarchy.model';
import { AMC_VIEWS } from '../../models/constants';
import { MomentDate } from '../../models/date.model';
import { useTranslations } from '../../services/i18n/useTranslations';
import '../../components/portfolio-performance/portfolio-performance.scss';
import {
  Filter,
  getquarterFilterOptions,
  getMonthFilterOptions,
  MenuItem,
  getItem,
} from '../../shared/helper';
import { useAccountManagerHierarchy } from '../portfolio-overview/useAccountManagerHierarchy';

interface PortfolioPerformanceProps {
  onTargetedAMsChange: (targetedAMs: string[]) => void;
  onFiltersChange: (filter: {
    verticals: string[];
    brands: string[];
    groups: string[];
    cities: string[];
  }) => void;
  children: ReactElement;
  isFilterDisabled: boolean;
  onRangeChange: (from: MomentDate, to: MomentDate, precision: 'month' | 'quarter' | 'day') => void;
  onTabChange: (tabValue: 'commercial' | 'operational' | 'all') => void;
  onSearch: (searchText: string) => void;
  view: string;
  filterData: {
    verticals: string[] | undefined;
    brands: Account[] | undefined;
    groups: Account[] | undefined;
    cities: string[] | undefined;
  };
  rowCount: number;
}

const EmptySpace = () => <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;
const ArrowRenderer = () => <CaretDownOutlined style={{ paddingLeft: '5px' }} />;
const CustomClearSelectionIcon = () => <CloseOutlined style={{ paddingLeft: '5px' }} />;

const createDropdownItems = (items: string[], selectedItem: string) => {
  return items.map((item) =>
    getItem(item, item, item === selectedItem ? <CheckOutlined /> : <EmptySpace />),
  );
};

export const PortfolioOverviewHeader = ({
  children,
  onTargetedAMsChange,
  onFiltersChange,
  isFilterDisabled,
  onRangeChange,
  onTabChange,
  onSearch,
  view,
  filterData,
  rowCount,
}: PortfolioPerformanceProps) => {
  const { t } = useTranslations();
  const { accountManagerHierarchy } = useAccountManagerHierarchy();
  const [tab, setTab] = useState<'commercial' | 'operational' | 'all'>('commercial');
  const quaterFilterOptions: Filter = getquarterFilterOptions();
  const monthFilterOptions: Filter = getMonthFilterOptions();
  const {
    verticals,
    brands,
    groups,
    cities,
    dateOption,
    dateTitle,
    country,
    tlOption,
    tlTitle,
    amOption,
    amTitle,
    dispatchFilter,
  } = useFilterState(
    quaterFilterOptions,
    monthFilterOptions,
    accountManagerHierarchy,
    view,
    onRangeChange,
    onTargetedAMsChange,
    onFiltersChange,
  );

  const countryDropdownItems = useMemo(
    () => createDropdownItems(accountManagerHierarchy?.countries || [], country),
    [accountManagerHierarchy?.countries, country],
  );

  const tlDropdownItems = useMemo(() => {
    if (view === AMC_VIEWS.REGIONAL_TEAM_LEAD) {
      let filteredList: SubOrdinates[] = accountManagerHierarchy?.subOrdinates || [];
      if (country) {
        filteredList = filteredList.filter((tl) => tl.country === country);
      }
      return filteredList.map((tl) =>
        getItem(tl.name, tl.id, tl.id === tlOption ? <CheckOutlined /> : <EmptySpace />),
      );
    }
    return [];
  }, [accountManagerHierarchy?.subOrdinates, tlOption, country, view]);

  const amDropdownItems = useMemo(() => {
    if (view === AMC_VIEWS.TEAM_LEAD) {
      return accountManagerHierarchy?.subOrdinates?.map((am) =>
        getItem(am.name, am.id, am.id === amOption ? <CheckOutlined /> : <EmptySpace />),
      );
    }
    if (view === AMC_VIEWS.REGIONAL_TEAM_LEAD) {
      let filteredList: SubOrdinates[] = accountManagerHierarchy?.subOrdinates || [];
      if (country) {
        filteredList = filteredList.filter((tl) => tl.country === country);
      }
      if (tlOption) {
        filteredList = filteredList.filter((tl) => tl.id === tlOption);
      }
      const items: MenuItem[] = [];
      filteredList.forEach((tl) => {
        tl.subOrdinates?.forEach((am) => {
          items.push(
            getItem(am.name, am.id, am.id === amOption ? <CheckOutlined /> : <EmptySpace />),
          );
        });
      });
      return items;
    }
    return [];
  }, [accountManagerHierarchy?.subOrdinates, amOption, country, tlOption, view]);

  const verticalItems: Option[] = useMemo(() => {
    if (filterData.verticals?.length) {
      return filterData.verticals
        .filter((v) => v)
        .map((v) => {
          return { label: v, value: v };
        });
    }
    return [];
  }, [filterData.verticals]);

  const brandItems: Option[] = useMemo(() => {
    if (filterData.brands?.length) {
      return filterData.brands
        .filter((v) => v)
        .map((v) => {
          return { label: v.name, value: v.id };
        });
    }
    return [];
  }, [filterData.brands]);

  const groupItems: Option[] = useMemo(() => {
    if (filterData.groups?.length) {
      return filterData.groups
        .filter((v) => v)
        .map((v) => {
          return { label: v.name, value: v.id };
        });
    }
    return [];
  }, [filterData.groups]);

  const cityItems: Option[] = useMemo(() => {
    if (filterData.cities?.length) {
      return filterData.cities
        .filter((v) => v)
        .map((v) => {
          return { label: v, value: v };
        });
    }
    return [];
  }, [filterData.cities]);

  const dateDropdownItems = useMemo(
    () => [
      ...createDropdownItems(Object.keys(quaterFilterOptions), dateOption),
      getItem(
        t.VendorDashboardLayoutInThisMonth,
        t.VendorDashboardLayoutInThisMonth,
        <EmptySpace />,
        createDropdownItems(Object.keys(monthFilterOptions), dateOption),
        'light',
      ),
    ],
    [dateOption, quaterFilterOptions, monthFilterOptions, t.VendorDashboardLayoutInThisMonth],
  );

  const handleTabClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const newTab = event.currentTarget.value as 'commercial' | 'operational' | 'all';
      setTab(newTab);
      onTabChange(newTab);
    },
    [onTabChange],
  );

  return (
    <div className="portfolio-performance">
      <div className="header">
        <div className="tab-container">
          <div className="left-items">
            <button
              type="button"
              value="commercial"
              onClick={handleTabClick}
              className={`tab-text ${tab === 'commercial' ? 'selected-tab' : ''}`}
            >
              Commercial
            </button>
            <button
              type="button"
              value="operational"
              onClick={handleTabClick}
              className={`tab-text ${tab === 'operational' ? 'selected-tab' : ''}`}
            >
              Operational
            </button>
          </div>
          <div className="right-items">
            <span> Total Accounts: {rowCount}</span>
          </div>
        </div>
        <div className="dropdown">
          <div style={{ display: 'flex', gap: '8px' }}>
            <FilterDropDown
              isFilterDisabled={isFilterDisabled}
              onFilterChange={(payload: string) => {
                dispatchFilter({ type: 'update_date', payload });
              }}
              dropdownitems={dateDropdownItems}
              title={dateTitle}
            />
            {!!countryDropdownItems.length && (
              <FilterDropDown
                isFilterDisabled={isFilterDisabled}
                onFilterChange={(payload: string) => {
                  dispatchFilter({ type: 'update_country', payload });
                }}
                dropdownitems={countryDropdownItems}
                title={country || 'Country'}
              />
            )}
            {!!tlDropdownItems.length && (
              <FilterDropDown
                isFilterDisabled={isFilterDisabled}
                onFilterChange={(payload: string) => {
                  dispatchFilter({ type: 'update_tl', payload });
                }}
                dropdownitems={tlDropdownItems}
                title={tlTitle || 'Team Lead'}
              />
            )}
            {!!amDropdownItems?.length && (
              <FilterDropDown
                isFilterDisabled={isFilterDisabled}
                onFilterChange={(payload: string) => {
                  dispatchFilter({ type: 'update_am', payload });
                }}
                dropdownitems={amDropdownItems}
                title={amTitle || 'Account Manager'}
              />
            )}
            {!!verticalItems.length && (
              <MultiSelect
                options={verticalItems}
                value={verticals}
                onChange={(payload: Option[]) => {
                  dispatchFilter({ type: 'update_vertical', payload });
                }}
                valueRenderer={(selected) => {
                  return selected.length ? `Vertical (${selected.length})` : 'Vertical';
                }}
                ArrowRenderer={ArrowRenderer}
                ClearSelectedIcon={<CustomClearSelectionIcon />}
                labelledBy="Vertical"
              />
            )}
            {!!brandItems.length && (
              <MultiSelect
                options={brandItems}
                value={brands}
                onChange={(payload: Option[]) => {
                  dispatchFilter({ type: 'update_brand', payload });
                }}
                valueRenderer={(selected) => {
                  return selected.length ? `Brands (${selected.length})` : 'Brands';
                }}
                ArrowRenderer={ArrowRenderer}
                ClearSelectedIcon={<CustomClearSelectionIcon />}
                labelledBy="Brands"
              />
            )}
            {!!groupItems.length && (
              <MultiSelect
                options={groupItems}
                value={groups}
                onChange={(payload: Option[]) => {
                  dispatchFilter({ type: 'update_group', payload });
                }}
                valueRenderer={(selected) => {
                  return selected.length ? `Groups (${selected.length})` : 'Groups';
                }}
                ArrowRenderer={ArrowRenderer}
                ClearSelectedIcon={<CustomClearSelectionIcon />}
                labelledBy="Groups"
              />
            )}
            {!!cityItems.length && (
              <MultiSelect
                options={cityItems}
                value={cities}
                onChange={(payload: Option[]) => {
                  dispatchFilter({ type: 'update_city', payload });
                }}
                valueRenderer={(selected) => {
                  return selected.length ? `Cities (${selected.length})` : 'Cities';
                }}
                ArrowRenderer={ArrowRenderer}
                ClearSelectedIcon={<CustomClearSelectionIcon />}
                labelledBy="Cities"
              />
            )}
            <button
              className="reset-button"
              type="button"
              onClick={() => {
                dispatchFilter({ type: 'reset', payload: '' });
              }}
            >
              Reset All
            </button>
          </div>
          <Search
            placeholder="Search Vendor Grid"
            onChange={(e) => onSearch(e.target.value.toUpperCase())}
            style={{ width: 200 }}
          />
        </div>
      </div>
      <div className="container">{children}</div>
    </div>
  );
};
